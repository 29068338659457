// Cards Styling

#stage-2d {
  #image-container {
    position: relative;
    width: 100%;
    height: 648px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

#stage-3d {
  position: relative;

  #canvas-container {
    position: relative;
    width: 100%;
    height: 600px;

    #canvas3d {
      position: absolute;
    }
  }
}

.dc-bjs-scene-loader-container {
  display: none;
}

.canvas-container canvas {
  opacity: 1;
  transition: all 250ms ease;
}

.fadeCanvasOut .canvas-container canvas {
  opacity: 0;
}

.dc-bjs-error-fileloading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 5rem;
}

.hidden {
  display: none !important;
}

.dc-bjs-loading-container > canvas {
  opacity: 0 !important;
}

.dc-bjs-scene-loader-container {
  left: 0;
  top: 0;
  transform: none;
  width: 100%;
  height: 100%;
}

.dc-bjs-scene-loader-container {
  position: absolute;
}

.dc-bjs-loading-container .dc-bjs-scene-loader-container {
  display: block;
}

canvas#canvas3dLoader {
  position: absolute;
}
