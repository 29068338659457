// Cards Styling

.card {
  transition: 0.3s;
  min-height: 115px;

  &.card-selected,
  &.card-active:hover {
    cursor: pointer;
  }

  &.card-active:hover {
    border: 1px solid $secondary;
  }

  &.card-selected {
    border: 1px solid $primary;
  }

  &.card-disabled {
    opacity: 0.5;
  }
}

.card-img-wrapper {
  text-align: center;
  margin-top: 0.5em;

  img {
    width: 90px;
    height: auto;
  }
}
