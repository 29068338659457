// Specs Styling

.copy-to-clipboard {
  button {
    text-decoration: none;
    padding: 0;
    margin: 0;
  }

  svg {
    margin-bottom: 5px;
  }
}
