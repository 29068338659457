// Nav Styling

.nav {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.nav::-webkit-scrollbar {
  display: none;
}
