// Fixes for Dark Mode

@include color-mode(dark) {
  .nav-tabs {
    .nav-link {
      color: #f0f0f0;
    }
  }

  .btn-outline-primary {
    color: #f0f0f0;
  }
}
