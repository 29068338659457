$enable-rounded: false;

$primary: #005db9;
$secondary: #2e3033;

$font-family-sans-serif: "Fira Sans", system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base;

$dark: $secondary;

@import "~bootstrap/scss/bootstrap";

@import "./scss/base";
@import "./scss/cards";
@import "./scss/darkmode";
@import "./scss/fonts";
@import "./scss/footer";
@import "./scss/header";
@import "./scss/nav";
@import "./scss/specs";
@import "./scss/utils";
