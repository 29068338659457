// Header Styling
header {
  nav {
    background-color: $light;
    .navbar-brand {
      img {
        height: 34px;
      }
    }
    .navbar-nav {
      margin-top: 3px;
    }
  }
}

@include color-mode(light) {
  header {
    nav {
      background-color: $light;
      .switch-light {
        display: none;
      }
    }
  }
}

@include color-mode(dark) {
  header {
    nav {
      background-color: $secondary;
      .switch-dark {
        display: none;
      }
    }
  }
}
