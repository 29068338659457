// Footer Styling

footer {
  .logo {
    img {
      width: 100px;
      height: auto;
    }
  }
}
